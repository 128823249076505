import axios from 'axios';

export default {
    state: () => ({}),
    mutations: {},
    actions: {
      post({dispatch}, data){
        return axios.post(window.location.origin +'/ui/v1/' + data.table + '/' + data.method, data.body).catch(error => {
          if(error.response.status === 401) {
            dispatch('botSingOut');
            dispatch('signOut');
            window.location.replace('/login');
          }
          else throw error;
        });
      },

      postAPI({dispatch}, data){
        return axios.post(window.location.origin +'/api/v1/' + data.controller + '/' + data.method, data.body).catch(error => {
          if(error.response.status === 401) {
            dispatch('botSingOut');
            dispatch('signOut');
            window.location.replace('/login');
          }
          else throw error;
        });
      },

      getAPI({dispatch}, data){
        return axios.get(window.location.origin +'/api/v1/' + data.controller + '/' + data.method).catch(error => {
          if(error.response.status === 401) {
            dispatch('botSingOut');
            dispatch('signOut');
            window.location.replace('/login');
          }
          else throw error;
        });
      },

      delete({dispatch}, data){
        return axios.delete(window.location.origin +'/ui/v1/' + data.table + '/' + data.method, data.body).catch(error => {
          if(error.response.status === 401) {
            dispatch('botSingOut');
            dispatch('signOut');            
            window.location.replace('/login');
          }
          else throw error;
        });
      },
  
      get({dispatch}, data){
        return axios.get(window.location.origin +'/ui/v1/' + data.table + '/' + data.method).catch(error => {
          if(error.response.status === 401) {
            dispatch('botSingOut');
            dispatch('signOut');
            window.location.replace('/login');
          } else throw error;
        });
      },

      getV2({dispatch}, data){
        return axios.get(window.location.origin +'/ui/v2/' + data.table + '/' + data.method + '?' + data.query).catch(error => {
          if(error.response.status === 401) {
            dispatch('botSingOut');
            dispatch('signOut');
            window.location.replace('/login');
          } else throw error;
        });
      },
  
      add({dispatch}, data){
        return dispatch("post", { table: data.table, method: "add", body: data.body });
      },
      update({dispatch}, data){
        return dispatch("post", { table: data.table, method: "update", body: data.body });
      },
      remove({dispatch}, data){
        return dispatch("delete", { table: data.table, method: "remove?id=" + data.body.id });
      },
      
      index({dispatch}, data) {
        return dispatch("get", { table: data.table, method: 'index?id=' + data.body.id });
      },
      section({dispatch}, data){
        return dispatch("get", { table: data.table, method: 'section' });
      },
      pagination({ dispatch }, data) {
            return dispatch("post", { table: data.table, method: 'pagination/' + data.query.page, body: data.query.filters.map(function (x) { return { Value: x.value, Field: x.field, Operation: x.type }; }) });
      },

      analytics({ dispatch }, data) {
        return dispatch("post", { table: data.table, method: 'analytics/' + data.query.page, body: data.query.filters.map(function (x) { return { Value: x.value, Field: x.field, Operation: x.type }; }) });
      },
      sectionV2({dispatch}, data){
        return dispatch("getV2", { table: data.table, method: 'section', query: 'page='+data.query.page+"&size="+data.query.size });
        },

        sectionV3({ dispatch }, data) {
            return dispatch("get", { table: data.table, method: 'sectionV2' });
        },
      getFilter({dispatch}, data) {
        return dispatch("get", { table: data.table, method: 'filter?id=' +  data.body.id+'&rootColumn=' + data.body.rootColumn});
      }
    },
    modules: {},
    getters: {},
  };
  