<template>
    <t-section v-bind:schemaName="'deliveryConfiguration'" v-bind:fields="fields" v-bind:headers="headers"></t-section>
</template>

<script>
    import TSection from '@/components/Sections/Section.vue';
    export default {
        name: "DeliverySection",
        components:{
            TSection,
        },
        data() {
            return {
                headers:[
                    { field:"value", name:"Апі ключ", sort:true, router: { field:"id", name:"DeliveryConfigurationPage" }},
                    { field:"name", name:"Назва особистого кабінету", sort:true,  router: { field:"id", name:"DeliveryConfigurationPage" } }, 
                    { field: "limit", name: "Поточна сума замовлень", sort: true, router: { field: "id", name: "DeliveryConfigurationPage" } }, 
                    { field: "limitValue", name: "Обмеження", sort: true, router: { field: "id", name: "DeliveryConfigurationPage" } }, 
                    { field:"isActive", name:"Активний", sort:true, isBoolean:true,  router: { field:"id", name:"DeliveryConfigurationPage" } }, 
                    { field:"isOnlyCashDelivery", name:"Лише для накладеного платежу", sort:true, isBoolean:true,  router: { field:"id", name:"DeliveryConfigurationPage" } }, 
                ],
                fields:[],
            }
        }
    }
</script>