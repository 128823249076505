<template>
    <t-section v-bind:schemaName="'withdrawal'" v-bind:fields="fields" v-bind:isHideButton="true" v-bind:headers="headers"></t-section>
</template>

<script>
    import TSection from '@/components/Sections/Section.vue';
    export default {
        name: "WithdrawalSection",
        components:{
            TSection,
        },
        data() {
            return {
                lookups:{
                    status:[]
                },
                headers:[
                    { field:"createdOn", name:"Дата создания", isDate: true, router: { field:"id", name:"WithdrawalPage" }},
                    { field:"contact.fullName", name:"Дроп", sort:true, router: { field:"id", name:"WithdrawalPage" }},
                    { field:"recipient", name:"Получатель", sort:true, router: { field:"id", name:"WithdrawalPage" }},
                    { field:"recipientCard", name:"Реквизиты получателя", sort:true, router: { field:"id", name:"WithdrawalPage" }},
                    { field:"status.name", name:"Статус", sort:true, router: { field:"id", name:"WithdrawalPage" }},
                    { field:"amount", name:"Кол-во: грн", sort:true, router: { field:"id", name:"WithdrawalPage" }}
                ]
            }
        },
        computed:{
            fields:{
                get(){
                    return [
                        { field: "number", name: "Номер", type:"number" },
                        { field: "createdOn", name: "Дата создания", type:"date" },
                        { field: "statusId", name: "Статус", type:"lookup", lookup: this.lookups.status },
                    ];
                }
            }
        },
        methods:{
            loadLookups(){
                this.$store.dispatch('section', { table: "withdrawalstatus", body: {}})
                .then((response) => {
                    this.lookups.status = response.data.data;
                });
            },
        },
        mounted() {
            this.loadLookups();
        }
    }
</script>