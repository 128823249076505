<template>
    <base-detail 
        v-bind:title="'Связь'"
        v-bind:rootId="rootId" 
        v-bind:rootColumn="rootColumn"
        v-bind:schemaName="'contactcommunication'"
        v-bind:headers="headers"
        v-bind:entity="entity"
        v-bind:lookups="lookups"        
        v-on:main-save="main-save"
        v-on:on-parrent-load="on-parrent-load">
        <template v-if="entity.contactId" v-slot="{entity, save}" class="input-group">
            <form 
                v-on:submit.prevent="save(entity)" 
                class="input-group mb-3">
                <input class="form-control" placeholder="Значение" v-model="entity.value" required>
                <select class="form-control" v-model="entity.typeId" required>
                    <option value="" disabled selected>Тип</option>
                    <option v-for="option in lookups.types"
                        v-bind:key="option.id" 
                        :value="option.id">
                            {{ option.name }}
                    </option>
                </select>
                <div class="input-group-append">
                    <button :disabled="!enabled"
                        class="btn btn-outline-secondary" 
                        type="submit">Добавить</button>
                </div>
            </form>
        </template>
    </base-detail>
</template>

<script>
import BaseDetail from '@/components/Sections/BaseDetail.vue';
export default {
    name: "ContactCommunicationDetail",
    components:{ BaseDetail },    
    emits:["on-parrent-load","main-save"],
    props:{
        rootId: { type:String, require:true },
        rootColumn: { type:String, require:true },
        enabled: { type: Boolean}
    },
    methods:{
        loadLookups() {
            this.$store.dispatch('section', { table: "communicationtype" })
            .then((response) => {
                this.lookups.types = response.data.data;
            });
        }
    },
    mounted(){
        this.loadLookups();
    },
    data(){
        return {
            lookups:{
                types:[]
            },
            entity:{
                typeId:"",
                contactId:this.rootId,
                value:"",
            },
            headers: [
                { field:"typeId", name:"Размер", sort:true, lookup:"types", enabeled: true },
                { field:"value", name:"Значение", sort:true, input: true, enabeled: true}
            ]
        };
    }
}
</script>