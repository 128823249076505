<template>
    <div class="scrolls-enabeled pt-2">
        <div v-for="(button, index) in buttons" v-bind:key="index" class="container-center btn-block btn-sm">
            <router-link v-if="button.path" :to="{ name: button.path }">
                <div class="text-center">
                    <img :src="button.img" class="icon-panel d-inline">
                    <div v-if="isExpanded" class="min-vw-100 ml-2 d-inline text-uppercase">
                        {{ button.name }}
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeftNavMenu",
    props:{
        isExpanded: Boolean,
        buttons: {
            type: Array,
            required: true
        },
    },
}
</script>

<style scoped>
.icon-panel {
    width: 50px;
    height: 50px;
}
a {
  color: white;
}

a.hover {
    color: white;
}

.container-center{
    width: 80%;
    margin: 0 auto;
    display: table;
}

.scrolls-enabeled {
    max-height: 100vh;
    overflow-y: auto;
}
</style>