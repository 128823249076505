<template>
  <div>
    Зачем лезеш сюда?
  </div>
</template>

<script>
export default {
    name:"LoginPage",
    mounted() {
      var token = this.$route.params.id;
      if(token !== null && token !== undefined) {
        this.$store.dispatch("botLogin", token);
          if (this.$route.params.page) {

              switch (this.$route.params.page.toUpperCase()) {
                  case "RESERVATIONDEFAULTPRODUCTPAGE":
                      this.$router.push({
                          name: this.$route.params.page,
                          params:
                          {
                              productId: this.$route.params.productId,
                              characterId: this.$route.params.characterId
                          }
                      });
                      break;
                  default:
                      this.$router.push({ name: this.$route.params.page });
                      break;
              }
          }
          else {
              this.$router.push({ path: "/bot" })
          }
        }
    }
}
</script>