<template>    
<div style="width:100%; height:100vh">
    <main-nav-menu v-if="!this.$store.getters.isBot" v-bind:buttons="topMenuButtons" class="bg-header text-right"></main-nav-menu>
    <div class="row">
        <left-nav-menu class="col-sm-1 bg-header" v-bind:isExpanded="isExpanded" v-bind:buttons="leftMenuButtons" />
        <router-view class="col-sm" />
    </div>
</div>
    <!--<div class="row" style="width:100%">
        <div class="bg-header min-vh-100 col-sm-1">
            <div class="d-block">
            <div class="mb-3 btn btn-sm text-light text-right" v-on:click="isExpanded = !isExpanded">
                <i class="fas fa-angle-up" v-if="isExpanded"></i>
                <i class="fas fa-angle-down" v-else></i>
            </div>
            </div>
        </div>
        <div class="col-sm m-0 p-0">
            <main-nav-menu v-if="!this.$store.getters.isBot" v-bind:buttons="topMenuButtons" class="bg-header text-right"></main-nav-menu>
            <router-view class="min-vh-100" />
        </div>
    </div>-->
</template>

<script>
import LeftNavMenu from '@/components/Navs/LeftNavMenu.vue';
import MainNavMenu from '@/components/Navs/MainNavMenu.vue';
export default {
  name: 'Home',
  components:{
    LeftNavMenu,
    MainNavMenu
  },
  computed:{
        topMenuButtons:{
            get(){
                let buttons = [
                    { 
                        path: "SignOut", 
                        name: this.$filters.localize("home.exit") 
                    }
                ];

                if(this.$store.getters.isAdmin){
                    buttons.unshift({ path: "DeliveryConfigurationSection", name:this.$filters.localize("home.delivery")});
                    buttons.unshift({ path: "SystemSetting", name:this.$filters.localize("home.settings")});
                    buttons.unshift({ path: "Hangfire", name:this.$filters.localize("home.hangfire")});
                    buttons.unshift({ path: "Swagger", name:this.$filters.localize("home.swagger")});
                }
                buttons.unshift({ path: "ProductCategory", name:this.$filters.localize("home.categories")});

                return buttons;
            }
        },
        leftMenuButtons:{
            get(){
                return [
                    { path: "Contact", name:this.$filters.localize("home.contacts"), img:require("@/assets/shop/png/001-man.png") },
                    { path: "Product", name:this.$filters.localize("home.products"), img:require("@/assets/shop/png/046-stock.png") },
                    { path: "Order", name:this.$filters.localize("home.orders"), img:require("@/assets/shop/png/036-aggregation.png") },
                    { path: "Withdrawal", name:this.$filters.localize("home.withdrawal"), img:require("@/assets/shop/png/049-credit-card.png") },
                    //{ path: "Campaign", name:this.$filters.localize("home.campaign"), img:require("@/assets/shop/png/024-discount-white.png") },            
                    { path: "Subscribe", name:this.$filters.localize("home.subscribe"), img:require("@/assets/shop/png/043-notebook.png") },                     
                    { path: "PaymentDetail", name:this.$filters.localize("home.payment"), img:require("@/assets/shop/png/011-wallet.png") },
                    { path: "Promotion", name:this.$filters.localize("home.promotion"), img:require("@/assets/shop/png/039-gift-card.png") },
                    { path: "Message", name:this.$filters.localize("home.message"), img:require("@/assets/shop/png/send-white.png") }
                ];
            }
        }
    },
    data(){
        return {
            isExpanded:true,
        }
    }
}
</script>

<style scoped>
@media (min-width: 250px)  
{
    .col-sm {
        overflow:auto;
        flex: 0 0 calc(100% - 150px);
        min-height: max-content;
    }
    .col-sm-1 {
        overflow-y:auto;
        flex: 0 0 150px;
        min-width: 150px;
        min-height: 100vh;
    }
}

div.nowrap 
{
    overflow: scroll;
    width: calc(100% - 150px);
    float:left;
    min-height: calc(100vh);
}

div.inline {
    float:left;
}
.flow-enabled {
    max-height: calc(100vh);
}

.left-block {
    max-width: 150px;
    min-height: calc(100vh + 100px);
}

.right-block {
    min-width: calc(100% - 150px);
    min-height: 100vh;
}
</style>