<template>
    <t-section v-bind:schemaName="'promotion'" v-bind:fields="fields" v-bind:headers="headers">
         <template v-slot:body="{ data, headers, getRowValue, router}">
            <tbody v-for="row of data" v-bind:key="row.id">
                <tr>
                    <td v-on:click.prevent="getProducts(row.id)" v-if="products.filter(x => x?.activityId == row.id).length == 0">+</td>
                    <td v-on:click.prevent="removeProducts(row.id)" v-else>-</td>
                    <td v-for="header of headers" v-bind:key="header.field+row.id" v-bind:class="getClassColor(getRowValue(row, header.config), header.config?.type)" 
                        v-on:click.prevent="router(getRowValue(row, header.router), header.router.name)">
                        {{ getRowValue(row, header) }}
                    </td>
                </tr>
                <td :colspan="headers.length+1" v-if="products.filter(x => x?.activityId == row.id).length > 0">
                    <table class="col">
                        <thead>               
                            <tr class="rounded shadow">
                                <th class="text-center">
                                    Код продукта
                                </th>
                                <th class="text-center">
                                    Размер
                                </th>
                                <th class="text-center">
                                    Количество
                                </th>
                            </tr>                
                        </thead>
                        <tbody>
                            <tr v-for="row of products.filter(x => x?.activityId == row.id)" v-bind:key="row.id">
                                <td>
                                    {{ row.product?.code }}
                                </td>
                                <td>
                                    {{ row.productRemain?.character?.name }}
                                </td>
                                <td>
                                     {{ row.quantity }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tbody>          
        </template>
    </t-section>
</template>

<script>
    import TSection from '@/components/Sections/Section.vue';
    export default {
        name: "PromotionSection",
        components:{
            TSection,
        },
        data() {
            return {
                headers:[
                    { field:"name", name:"Название", sort:true, router: { field:"id", name:"PromotionPage" }},
                    { field:"value", name:"Цена", sort:true, router: { field:"id", name:"PromotionPage" }}
                ],
                fields:[],
            }
        }
    }
</script>