<template>
  <t-section
    v-bind:schemaName="'order'"
    v-bind:method="'analytics'"
    v-bind:fields="fields"
    v-bind:headers="headers"
    v-bind:isHideButton="true"
  >
    <template v-slot:custom>
      <sender-detail />
    </template>
  </t-section>
</template>

<script>
import SenderDetail from '@/views/details/messages/SenderDetail.vue'
import TSection from '@/components/Sections/SectionV3.vue';
export default {
    name:"OrderAnalyst",
    components:{
        TSection,
        SenderDetail
    },
    data(){
        return {
            rows:[],
            fields: { 
                get() {
                    return [
                        { field: "position", name: 'Рейтинг', type: "number" },
                        { field: "contact.fullName", name: "Контакт", type: "string" },
                        { field: "count", name: "Количество", type: "number" },
                        { field: "contact.personalBonus", name: "Бонус", type: "string"}
                    ];
                }
            },
            headers:[
                { field:"position", name:"Рейтинг" },
                { field:"contact.fullName", name:"Контакт"},
                { field:"count", name:"Количество"},
                { field:"contact.personalBonus", name:"Бонус"}
            ]
        }
    }
}
</script>
