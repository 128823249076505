<template>
    <div class="container text-center">
        <div class="container mt-5 pt-5" style="max-width:500px">
            <img src="../assets/logo-b-fiolet.png" />
            <form @submit.prevent="login" >
                <label class="d-flex required">Логин</label>
                <div class="form-group justify-content-between">
                    <input id="email" v-model="userName" type="text" class="form-control" placeholder="Логин" required />
                </div>
                <label class="d-flex required">Пароль</label>
                <div class="form-group">
                    <input id="password" v-model="userPassword" type="password" class="form-control" placeholder="Пароль" required />
                </div>
                <div class="col-sm input-group-append">
                    <button class="btn btn-outline-da btn-block" type="submit">Войти</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VLogin',
        data() {
            return {
                userName: "",
                userPassword: ""
            };
        },
        methods: {
            login() {         
                this.$store.dispatch("login", { UserName: this.userName, UserPassword: this.userPassword })
                .then(() => this.$router.push({name: 'Contact'}))
                .catch(error => 
                {
                    this.$toast.error(this.$filters.localize("login.invalid"));
                    console.log(error);
                });
            }
        },
    }
</script>


