<template>
  <base-detail
    v-bind:title="'Налаштування'"
    v-bind:rootId="rootId"
    v-bind:rootColumn="rootColumn"
    v-bind:schemaName="'campaignrequirement'"
    v-bind:headers="headers"
    v-bind:entity="entity"
    v-on:main-save="main - save"
    v-on:on-parrent-load="on - parrent - load"
  >
    <template v-slot="{ entity, add }" class="input-group">
      <form v-on:submit.prevent="addRequirement(entity, add)" class="input-group mb-3">
        <input
          class="form-control"
          placeholder="Місце"
          type="numeric"
          min="1"
          v-model="entity.position"
          required
        />
        <input
          class="form-control"
          placeholder="Нагорода"
          type="numeric"
          min="1"
          v-model="entity.award"
          required
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="submit">
            Добавить
          </button>
        </div>
      </form>
    </template>
  </base-detail>
</template>

<script>
import BaseDetail from '@/components/Sections/BaseDetail.vue'
export default {
  name: 'CampaignRequirementDetail',
  components: { BaseDetail },
  emits: ['on-parrent-load', 'main-save'],
  props: {
    rootId: { type: String, require: true },
    rootColumn: { type: String, require: true },
    enabled: { type: Boolean },
  },

  methods: {
    addRequirement(entity, callback) {
      if (entity.position > 0) {
        if (!this.enabled) this.save(entity, callback)
        else this.add(entity, callback)
      }
    },
    async add(entity, callback) {
      try {
        var response = await this.$store.dispatch('post', {
          table: 'campaignrequirement',
          method: 'add',
          body: JSON.parse(JSON.stringify(entity)),
        })

        callback(response.data.data)
        this.$emit('on-parrent-load')
      } catch (error) {
        this.$toast.error(error.response.data.message)
      }
    },

    save(entity, callback) {
      new Promise((resolve, reject) => {
        this.$emit('main-save', null, resolve, reject)
      }).then(() => this.add(entity, callback))
    },
  },
  data() {
    return {
      entity: {
        campaignId: this.rootId,
        award: 0,
        position: 0,
      },
      headers: [
        {
          field: 'position',
          name: 'Місце',
          sort: true,
          input: { type: 'numeric' },
          enabeled: true,
        },
        {
          field: 'award',
          name: 'Нагорода',
          sort: true,
          input: { type: 'numeric' },
          enabeled: true,
        },
        { field: 'id', name: 'Дія', action: true },
      ],
    }
  },
}
</script>
