<template>
    <div>
        <base-page v-bind:schemaName="'deliveryConfiguration'">
            <template v-slot:panel-button></template>
            <template v-slot:panel-left="{ entity }">
            <div class="mb-3 text-left">
                    <label class="form-label">Назва особистого кабінету</label>
                    <input class="form-control" v-model="entity.name" required>
                </div>
                <div class="mb-3 text-left">
                    <label class="form-label">Апі Ключ</label>
                    <input class="form-control" v-model="entity.value" required>
                </div>
                <div class="mb-3 text-left">
                    <label class="form-label">Поточна сума замовлень</label>     
                    <input class="form-control" v-model="entity.limit">
                </div>
                <div class="mb-3 text-left">
                    <label class="form-label">Обмеження</label>                
                    <input class="form-control" v-model="entity.limitValue">
                </div>
                <div class="mb-3 text-left">                    
                        <label class="form-label">Активний</label> 
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="entity.isActive" id="isActive">
                            <label class="form-check-label" for="isActive">
                                {{ entity.isActive ? "Так" : "Ні"}}
                            </label>
                        </div>
                    </div>
                    <div class="mb-3 text-left">                        
                        <label class="form-label">Лише для накладеного платежу</label> 
                         <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="entity.isOnlyCashDelivery" id="isOnlyCashDelivery">
                            <label class="form-check-label" for="isOnlyCashDelivery">
                                {{ entity.isOnlyCashDelivery ? "Так" : "Ні"}}
                            </label>
                        </div>
                    </div>
                <div class="mb-3 text-left">
                    <label class="form-label">Служба доставки</label>
                    <select :disabled="deliveryServices.length === 0" class="form-control" v-model="entity.deliveryServiceId" required>
                        <option v-for="option in deliveryServices" v-bind:key="option.id" :value="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <div class="mb-3 text-left">
                    <label class="form-label">Місто</label>
                    <scity :entity="{ city:entity.cityId, cityName: entity.cityName }" @update="(value) => { entity.cityId = value.code; entity.cityName = value.label; }"/>
                </div>
                <div class="mb-3 text-left">
                    <label class="form-label">Відділення</label>                
                    <input class="form-control" v-model="entity.department">
                </div>
            </template>
        </base-page>
    </div>
</template>


<script>
import scity from "@/components/scity.vue";
import BasePage from '@/components/Sections/BasePage.vue';
export default {
    name: "DeliveryPage",
    components:{ BasePage, scity},
    data(){
        return {
            cities:[],
            deliveryServices:[]
        }
    },
    mounted() {
        this.onLoadLookups();
    },
    methods:{
        onLoadLookups() {
            this.$store.dispatch('getAPI', { controller: "address", method: "getCities" })
            .then((response) => {
                this.cities = response.data.data;
            });
            
            this.$store.dispatch('section', { table: "deliveryService" })
            .then((response) => {
                this.deliveryServices =  response.data.data;
            });
        }
    }
}
</script>