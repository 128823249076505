<template>
    <base-detail 
        v-bind:title="'Подарки'"
        v-bind:rootId="rootId" 
        v-bind:rootColumn="rootColumn"
        v-bind:schemaName="'promotioninorder'"
        v-bind:headers="headers"
        v-bind:entity="entity"
        v-on:main-save="main-save"
        v-on:on-parrent-load="on-parrent-load"
        v-bind:lookups="lookups">
        <template v-if="entity.orderId" v-slot="{entity, save}" class="input-group">
            <form 
                v-on:submit.prevent="save(entity)" 
                class="input-group mb-3">
                <select class="form-control" v-model="entity.promotionId" required>
                    <option v-for="option in lookups.promotions"
                        v-bind:key="option.id" 
                        :value="option.id">
                            {{ option.name }}
                    </option>
                </select>
                <div class="input-group-append">
                    <button :disabled="!enabled"
                        class="btn btn-outline-secondary" 
                        type="submit">Добавить</button>
                </div>
            </form>
        </template>
    </base-detail>
</template>

<script>
import BaseDetail from '@/components/Sections/BaseDetail.vue';
export default {
    name: "OrderPromotionDetail",
    components:{ BaseDetail },
    emits:["on-parrent-load","main-save"],
    props:{
        rootId: { type:String, require:true },
        rootColumn: { type:String, require:true },
        enabled: { type: Boolean}
    },
    methods:{
        loadLookups() {
            this.$store.dispatch('section', { table: "promotion" })
            .then((response) => {
                this.lookups.promotions = response.data.data;
            });
        }
    },
    mounted(){
        this.loadLookups();
    },
    data(){
        return {
            lookups:{
                promotions:[]
            },
            entity:{
                promotionId:"",
                orderId:this.rootId,
            },
            headers: [
                { field: "promotion.name", name: "Подарок", sort: true },
                { field: "id", name: "Действие", action: true }
            ]
        };
    }
}
</script>