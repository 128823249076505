<template>
    <base-page v-bind:schemaName="'systemSetting'" v-bind:isHideRemove="true">
        <template v-slot:panel-left="{entity}">
            <div class="mb-3 text-left">
                <label class="form-label">Название</label>
                <input type="text" class="form-control" v-model="entity.name" required>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Код</label>
                <input type="text" class="form-control" v-model="entity.code" required>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Значение</label>
                <input class="form-control" v-model="entity.value" required>
            </div>            
            <div class="mb-3 text-left">
                <label class="form-label">Описание</label>
                <input class="form-control" v-model="entity.description">
            </div>
        </template>
        
        <template v-slot:header>
        </template>
    </base-page>
</template>


<script>
import BasePage from '@/components/Sections/BasePage.vue';
export default {
    name: "SystemSettingPage",
    components:{ BasePage }
}

</script>