<template>
    <div class="flow-enabled">
        <div>
            <div
              v-if="!isCustomIconPanel"
              class="text-left"
            ></div>
            <slot
              v-else
              name="icon-panel"
            ></slot>
        </div>
        <div class="row">
            <div
              class="text-left pt-2 pb-2 col-sm-2"
              v-if="!isHideButton"
            >
                <div class="bg-white rounded shadow">
                    <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-success"
                          v-on:click="router(uuid, editPage)"
                        >{{ $filters.localize("button.add") }}</button>
                        <button
                          v-if="this.filters.length > 0"
                          type="button"
                          class="btn btn-sm btn-outline-info"
                          v-on:click="clearFilters"
                        >Очистить фильтр</button>
                        <slot
                          name="buttons"
                          class="col-sm-1"
                          :filters="filters"
                        ></slot>
                    </div>
                </div>
            </div>
        </div>
        <form
          class="input-group  pr-4"
          v-if="this.fields.length > 0"
          v-on:submit.prevent="addFilter(filter)"
        >
            <select
              class="form-control"
              v-model="filter.field"
            >
                <option
                  v-for="option in fields"
                  v-bind:key="option.field"
                  :value="option.field"
                >
                    {{ option.name }}
                </option>
            </select>
            <select
              class="form-control"
              v-model="filter.type"
            >
                <option
                  v-for="option in typesFilterfilter(filter)"
                  v-bind:key="option.value"
                  :value="option.value"
                >
                    {{ option.name }}
                </option>
            </select>
            <input
              v-if="currentInputType(filter) != 'lookup'"
              :type="currentInputType(filter)"
              class="form-control text-center"
              v-model="filter.value"
              required
            >
            <select
              v-else
              class="form-control"
              v-model="filter.value"
              required
            >
                <option
                  v-for="option in fields.find(x => x.field == filter.field).lookup"
                  v-bind:key="option.id"
                  :value="option.id"
                >
                    {{ option.name }}
                </option>
            </select>
            <div class="input-group-append">
                <button
                  :disabled="!(!!filter.value && !!filter.field && !!filter.type) && currentInputType(filter) != 'checkbox'"
                  class="btn btn-outline-secondary"
                >Применить фильтр</button>
            </div>
        </form>
        <div
          class="row"
          v-if="filters.length > 0"
        >
            <div
              class="input-group col-sm"
              v-for="(filter, index) in filters"
              v-bind:key="index"
            >
                <label
                  class="form-control text-left"
                  v-on:click.prevent="removeFilter(index)"
                >
                    {{ getFilterString(filter) }}
                </label>
                <select
                  class="form-control"
                  v-model="filter.type"
                >
                    <option
                      v-for="option in typesFilterfilter(filter)"
                      v-bind:key="option.value"
                      :value="option.value"
                    >
                        {{ option.name }}
                    </option>
                </select>

                <input
                  v-if="currentInputType(filter) != 'lookup'"
                  :type="currentInputType(filter)"
                  class="form-control text-center"
                  v-model="filter.value"
                  required
                >
                <select
                  v-else
                  class="form-control"
                  v-model="filter.value"
                  required
                >
                    <option
                      v-for="option in fields.find(x => x.field == filter.field).lookup"
                      v-bind:key="option.id"
                      :value="option.id"
                    >
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="row">
            <slot
              name="count"
              class="col-sm"
              :data="pagination"
            ></slot>
            <div class="col-sm text-right mr-4">Количество: {{ pagination.count }}</div>
        </div>
        <div
          class="rounded shadow bg-white mr-3 flow-enabled table-container"
          style="height:84vh"
        >
            <table class="table table-hover table-striped">
                <thead>
                    <tr class="">
                        <th v-if="isCustomRow"></th>
                        <th
                          scope="col"
                          class="text-center"
                          v-for="header of headers"
                          v-bind:key="header.field"
                        >
                            <p class="d-inline">{{ header.name }} &nbsp;</p>
                            <fas-sort
                              class="d-inline"
                              v-if="header.sort"
                              @click="sort(header.field)"
                              v-bind:currentSort="currentSort"
                              v-bind:currentSortDir="currentSortDir"
                              v-bind:field="header.field"
                            />
                        </th>
                    </tr>
                </thead>
                <slot
                  name="body"
                  v-if="isCustomRow"
                  :data="rowsData"
                  :headers="headers"
                  :getRowValue="getRowValue"
                  :router="router"
                  :routerOpen="routerOpen"
                >
                </slot>
                <tbody v-else>
                    <tr
                      v-for="row of rowsData"
                      v-bind:key="row.id"
                    >
                        <td
                          v-bind:class="header.class ? header.class : ''"
                          v-for="header of headers"
                          v-bind:key="row.id + header.field"
                          v-on:click.middle.prevent="routerOpen(getRowValue(row, header.router), header.router?.name)"
                          v-on:click.prevent="router(getRowValue(row, header.router), header.router?.name)"
                        >
                            {{ getRowValue(row, header) }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div
              v-if="this.pagination.entities?.length == 0"
              class="text-center"
            >
                Данные отсутствуют
            </div>
            <div class="d-flex justify-content-between align-items-center row w-100">
                <nav
                  class="col"
                  v-if="pagination.entities?.length != 0"
                >
                    <ul class="pagination">
                        <li
                          class="page-item"
                          v-if="currentPage - 1 > 0"
                        >
                            <a
                              class="page-link"
                              v-on:click="backPage"
                              aria-label="Предидущая"
                            >
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li
                          class="page-item"
                          v-if="currentPage - 1 > 0"
                        ><a
                              class="page-link"
                              v-on:clilck="backPage"
                            >{{ currentPage - 1 }}</a></li>
                        <li class="page-item"><a
                              class="page-link"
                              disable
                            >{{ currentPage }}</a></li>
                        <li
                          class="page-item"
                          v-if="pagination.totalPages > currentPage - 1"
                        ><a
                              class="page-link"
                              v-on:click="nextPage"
                            >{{ currentPage + 1 }}</a></li>
                        <li
                          class="page-item"
                          v-if="pagination.totalPages > currentPage - 1"
                        >
                            <a
                              class="page-link"
                              v-on:click="nextPage"
                              aria-label="Следующая"
                            >
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

            <div
              v-if="loading"
              class="loader-overlay"
            >
                <div
                  class="spinner-border text-primary"
                  role="status"
                >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import fasSort from '@/components/Sections/FasSort.vue';
import table from '@/mixins/table.js';
export default {
    name: "TSection",
    mixins: [table],
    props: ["isHideButton", "isCustomRow", "isCustomIconPanel"],
    components: {
        fasSort
    },
    data() {
        return {
            uuid: uuidv4(),
        }
    },
    computed: {
        editPage: {
            get() {
                return this.schemaName.charAt(0).toUpperCase() + this.schemaName.slice(1) + 'Page';
            }
        },
    },
    methods: {
        typesFilterfilter(filter) {
            if (filter.field) {
                var type = this.fields.find(x => x.field == filter.field)?.type ?? "all";
                return this.filterTypes.filter(x => x.type == "all" || x.type.includes(type));
            }
            return [];
        },
        currentInputType(filter) {
            if (filter.field) {
                var type = this.fields.find(x => x.field == filter.field)?.type ?? "all";
                return type == "all" ? "text" : type;
            }
            return "text";
        }
    },
    async mounted() {
        await this.loadRows();
    }
}
</script>

<style scoped>
table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
}

.table-container {
    position: relative;
}

.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    /* Напівпрозорий фон */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    /* Важливо, щоб лоадер був поверх таблиці */
}
</style>