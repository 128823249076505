<template>
  <div>
        <top-nav-menu v-bind:buttons="topNav" class="bg-header mb-3"/>
        <router-view />
  </div>
</template>

<script>
import TopNavMenu from '@/components/Navs/TopNavMenu.vue';
export default {
    name:"HomePage",
    data(){
        return { 
            showReport:false,           
            topNav:[                
                { path: "ReservationsPage", name: "Активные брони" },
                { path: "OrdersBotPage", name: "Заказы" }
            ]
        }
    },
    components:{ 
        TopNavMenu
    }
}
</script>
<style scoped>
a {
    color: white;
}
</style>