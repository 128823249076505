<template>
  <t-section
    v-bind:schemaName="'order'"
    v-bind:defaultSort="'desc'"
    v-bind:isCustomRow="isCustomRow"
    v-bind:fields="fields"
    v-bind:headers="headers"
  >
    <template v-slot:analytics></template>
    <template v-slot:custom>
      <sender-detail />
    </template>

    <template v-slot:count="{ data }">
      <div class="col-sm text-right mr-4">Сумма наложки: {{ data.sum }} грн</div>
    </template>

    <template v-slot:body="{ data, headers, getRowValue, router, routerOpen }">
      <tbody v-for="row of data" v-bind:key="row.id">
        <tr>
          <td
            v-on:click.prevent="getProducts(row.id)"
            v-if="products.filter((x) => x?.activityId == row.id).length == 0"
          >
            +
          </td>
          <td v-on:click.prevent="removeProducts(row.id)" v-else>-</td>
          <td
            v-for="header of headers"
            v-bind:key="header.field + row.id"
            v-bind:class="
              getClassColor(getRowValue(row, header.config), header.config?.type)
            "
            v-on:click.middle.prevent="
              routerOpen(getRowValue(row, header.router), header.router?.name)
            "
            v-on:click.prevent="
              router(getRowValue(row, header.router), header.router.name)
            "
          >
            {{ getRowValue(row, header) }}
          </td>
        </tr>
        <td
          :colspan="headers.length + 1"
          v-if="products.filter((x) => x?.activityId == row.id).length > 0"
        >
          <table class="col">
            <thead>
              <tr class="rounded shadow">
                <th class="text-center">Код продукта</th>
                <th class="text-center">Размер</th>
                <th class="text-center">Количество</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="row of products.filter((x) => x?.activityId == row.id)"
                v-bind:key="row.id"
              >
                <td>
                  {{ row.product?.code }}
                </td>
                <td>
                  {{ row.productRemain?.character?.name }}
                </td>
                <td>
                  {{ row.quantity }}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tbody>
    </template>
  </t-section>
</template>

<script>
import SenderDetail from '@/views/details/messages/SenderDetail.vue'
import TSection from '@/components/Sections/SectionV3.vue'
export default {
  name: 'OrderSection',
  components: {
    TSection,
    SenderDetail,
  },
  methods: {
    loadLookups() {
      this.$store
        .dispatch('section', { table: 'orderstatus', body: {} })
        .then((response) => {
          this.lookups.status = response.data.data
        })
      this.$store
        .dispatch('section', { table: 'deliveryType', body: {} })
        .then((response) => {
          this.lookups.deliveryType = response.data.data
        })
      this.$store
        .dispatch('section', { table: 'deliveryService', body: {} })
        .then((response) => {
          this.lookups.deliveryService = response.data.data
        })
      this.$store
        .dispatch('section', { table: 'paymentStatus', body: {} })
        .then((response) => {
          this.lookups.paymentStatus = response.data.data
        })
      this.$store.dispatch('section', { table: 'paymentdetail' }).then((response) => {
        this.lookups.fops = response.data.data
      })
    },
    getProducts(rootId) {
      this.$store
        .dispatch('getFilter', {
          table: 'orderproduct',
          body: { id: rootId, rootColumn: 'ActivityId' },
        })
        .then((response) => {
          this.products = [...this.products, ...response.data.data]
        })
    },
    removeProducts(rootId) {
      this.products = this.products.filter((x) => x?.activityId !== rootId)
    },
    getClassColor(value, type) {
      if (type === 'delivery') {
        switch (value) {
          case '1bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-new-post'
          case '2bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-ua-post'
          default:
            return null
        }
      } else if (type === 'payment') {
        switch (value) {
          case '1bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-full-payment'
          case '2bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-partial-payment'
          default:
            return null
        }
      } else if (type === 'status') {
        switch (value) {
          case '1bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-status-new'
          case '2bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-status-reserv'
          case '3bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-status-wait'
          case '4bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-status-confirm'
          case '5bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-status-on-the-way'
          case '6bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-status-close'
          case '7bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-status-refund'
          case '8bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-status-cancel'
          case '9bf61851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-status-resort'
          case '0bf62851-347c-4d0d-a62e-d488dce9c0da':
            return 'row-status-wait-confirm'
          default:
            return null
        }
      }
    },
  },
  computed: {
    fields: {
      get() {
        return [
          { field: 'number', name: 'Номер', type: 'number' },
          {
            field: 'statusId',
            name: 'Статус',
            type: 'lookup',
            lookup: this.lookups.status,
          },
          {
            field: 'paymentStatusId',
            name: 'Статус оплаты',
            type: 'lookup',
            lookup: this.lookups.paymentStatus,
          },
          { field: 'createdOn', name: 'Дата создания', type: 'date' },
          { field: 'contact.fullName', name: 'Ответственный', type: 'string' },
          { field: 'client', name: 'Клиент (Прайс)', type: 'string' },
          { field: 'comment', name: 'Комментарий', type: 'string' },
          {
            field: 'deliveryServiceId',
            name: 'Почта',
            type: 'lookup',
            lookup: this.lookups.deliveryService,
          },
          { field: 'city', name: 'Город', type: 'string' },
          { field: 'recipientPhone', name: 'Номер клиента', type: 'string' },
          { field: 'recipient', name: 'Получатель', type: 'string' },
          { field: 'ttn', name: 'ТТН', type: 'string' },
          { field: 'paymentPayer', name: 'Плательщик', type: 'string' },
          {
            field: 'paymentDetailId',
            name: 'ФОП (счет оплаты)',
            type: 'lookup',
            lookup: this.lookups.fops,
          },
          {
            field: 'deliveryTypeId',
            name: 'Тип оплаты',
            type: 'lookup',
            lookup: this.lookups.deliveryType,
          },
          { field: 'products.Product.Code.Custom', name: 'Код продукта', type: 'all' },
          { field: 'products.Character.Code.Custom', name: 'Размер', type: 'all' },
        ]
      },
    },
  },
  data() {
    return {
      isCustomRow: true,
      products: [],
      lookups: {
        status: [],
        deliveryType: [],
        deliveryService: [],
        fops: [],
      },
      headers: [
        {
          field: 'number',
          name: 'Номер',
          sort: true,
          config: { field: 'deliveryServiceId', type: 'delivery' },
          router: { field: 'id', name: 'OrderPage' },
        },
        {
          field: 'contact.fullName',
          name: 'Ответственный',
          sort: true,
          router: { field: 'id', name: 'OrderPage' },
        },
        {
          field: 'client',
          name: 'Клиент (Прайс) ',
          sort: true,
          router: { field: 'id', name: 'OrderPage' },
        },
        {
          field: 'createdOn',
          name: 'Дата создания',
          isDate: true,
          sort: true,
          router: { field: 'id', name: 'OrderPage' },
        },
        {
          field: 'deliveryService.name',
          name: 'Почта',
          sort: true,
          config: { field: 'deliveryServiceId', type: 'delivery' },
          router: { field: 'id', name: 'OrderPage' },
        },
        {
          field: 'deliveryType.name',
          name: 'Тип оплаты',
          sort: true,
          config: { field: 'deliveryTypeId', type: 'payment' },
          router: { field: 'id', name: 'OrderPage' },
        },
        {
          field: 'recipientPhone',
          name: 'Номер клиента',
          router: { field: 'id', name: 'OrderPage' },
        },
        {
          field: 'recipient',
          name: 'Получатель',
          sort: true,
          router: { field: 'id', name: 'OrderPage' },
        },
        { field: 'ttn', name: 'ТТН' },
        { field: 'comment', name: 'Комментарий' },
        {
          field: 'status.name',
          name: 'Статус',
          sort: true,
          config: { field: 'statusId', type: 'status' },
          router: { field: 'id', name: 'OrderPage' },
        },
        {
          field: 'paymentStatus.name',
          name: 'Статус оплаты',
          sort: true,
          router: { field: 'id', name: 'OrderPage' },
        },
      ],
    }
  },
  mounted() {
    this.loadLookups()
  },
}
</script>

<style scoped>
.row-new-post {
  background-color: #239ef0;
  color: black;
}
.row-ua-post {
  background-color: #05be0e;
  color: black;
}

.row-full-payment {
  background-color: #ffd375;
  color: black;
}

.row-partial-payment {
  background-color: #bb0303;
  color: white;
}
.row-superimposed {
  background-color: #8f3030;
  color: white;
}
.row-full-payment {
  background-color: #ffd375;
  color: black;
}

.row-status-new {
  color: black;
}
.row-status-wait {
  background-color: #8500b9;
  color: white;
}
.row-status-reserv {
  background-color: #bbc8ba;
  color: black;
}
.row-status-confirm {
  background-color: white;
  color: black;
}
.row-status-wait-confirm {
  background-color: #239ef0;
  color: white;
}
.row-status-on-the-way {
  background-color: #05be0e;
  color: black;
}
.row-status-close {
  background-color: #5e5e5e;
  color: white;
}
.row-status-refund {
  background-color: #693b3b;
  color: white;
}
.row-status-cancel {
  background-color: #8f3030;
  color: white;
}
.row-status-resort {
  background-color: #8f3030;
  color: white;
}
</style>
