<template>
    <base-page v-bind:schemaName="'order'" v-bind:isHideRemove="true">
        <template v-slot:panel-button="{ entity, save }">
            <button v-if="entity.statusId === '08f66932-0655-4f13-af06-3df137f75cf1'" 
                type="button" 
                v-on:click.prevent="moveParentOrder(entity.parentId)" 
                class="btn btn-sm btn-outline-warning">
                    Переглянути основне замовлення
                </button>
            <button v-if="entity.statusId === '2bf61851-347c-4d0d-a62e-d488dce9c0da' || entity.statusId === '0bf62851-347c-4d0d-a62e-d488dce9c0da'" 
                type="submit" 
                v-on:click.prevent="confirmOrder(entity, save)" 
                class="btn btn-sm btn-outline-success">
                    Перевести к заказу
            </button>
            <button v-if="entity.statusId === '3bf61851-347c-4d0d-a62e-d488dce9c0da'" 
                type="submit" 
                v-on:click.prevent="confirmSend(entity, save)" 
                class="btn btn-sm btn-outline-success">
                    Отправить
            </button>
            <button v-if="entity.statusId === '5bf61851-347c-4d0d-a62e-d488dce9c0da'" 
                type="submit" 
                v-on:click.prevent="confirmRefund(entity, save)" 
                class="btn btn-sm btn-outline-success">
                    Возврат
            </button>
            <button v-if="entity.statusId === '5bf61851-347c-4d0d-a62e-d488dce9c0da'" 
                type="submit" 
                v-on:click.prevent="confirmDone(entity, save)" 
                class="btn btn-sm btn-outline-success">
                    Закрыть
            </button>
            <button v-if="entity.statusId === '1bf61851-347c-4d0d-a62e-d488dce9c0da'" 
                type="submit"
                v-on:click.prevent="confirmReserv(entity, save)" 
                class="btn btn-sm btn-outline-info">
                    В резервы
            </button>
            <button v-if="entity.statusId !== '8bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId !== '08f66932-0655-4f13-af06-3df137f75cf1'  && entity.statusId !== '6bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId !== '7bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId !== '9bf61851-347c-4d0d-a62e-d488dce9c0da'" 
                type="submit" 
                v-on:click.prevent="resort(entity)" 
                class="btn btn-sm btn-outline-primary">
                    Пересорт, брак
            </button>
            <button v-if="entity.statusId !== '8bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId !== '08f66932-0655-4f13-af06-3df137f75cf1' && entity.statusId !== '6bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId !== '7bf61851-347c-4d0d-a62e-d488dce9c0da'" 
                type="submit" 
                v-on:click.prevent="cancel(entity)" 
                class="btn btn-sm btn-outline-danger">
                    Отменить
            </button>
            <button 
                v-if="entity.deliveryServiceId == '1bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.city && entity.address && entity.deliveryTypeId && entity.deliveryServiceId && entity.recipient && entity.recipientPhone"
                type="submit" 
                v-on:click.prevent="generate(entity)" 
                class="btn btn-sm btn-outline-primary">
                    Генерувати ТТН
            </button>
        </template>
        <template v-slot:panel-left="{entity}">
            <div class="mb-3 text-left">
                <label class="form-label">Номер</label>
                <input :disabled="true" type="text" class="form-control" v-model="entity.number">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">ТТН</label>
                <input :disabled="!isEnabeled(entity)" type="text" class="form-control" v-model="entity.ttn">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Статус</label>
                <select :disabled="true" class="form-control" v-model="entity.statusId" required>
                    <option v-for="option in statuses" v-bind:key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Статус оплаты</label>
                <select :disabled="entity.paymentStatusId == '2bf61851-347c-4d0d-a62e-d488dce9c0da' 
                    || entity.paymentStatusId == '3bf61851-347c-4d0d-a62e-d488dce9c0da'"
                    class="form-control" v-model="entity.paymentStatusId">
                    <option v-for="option in paymentStatuses" v-bind:key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>       
            <div class="mb-3 text-left" v-if="isEnabeled(entity) && entity.statusId == '2bf61851-347c-4d0d-a62e-d488dce9c0da' || entity.statusId == '1bf61851-347c-4d0d-a62e-d488dce9c0da'">
                <label class="form-label">Время сгорания</label>
                <input :disabled="!isEnabeled(entity)" type="datetime-local" class="form-control" v-model="entity.reservationExpired">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Клиент (Прайс)</label>
                <input class="form-control" type="text" v-model="entity.client" @change="changeContactId(entity.client, entity)" list="contacts" required>
                <datalist id="contacts">
                    <option v-for="contact in contacts" :value="contact.fullName" :key="contact.id">{{ contact.fullName }}</option>
                </datalist>
            </div>     
            <div class="mb-3 text-left">
                <label class="form-label">Ответственный</label>
                <select :disabled="contacts.length === 0 || !isEnabeled(entity)" class="form-control" v-model="entity.contactId">
                    <option v-for="option in contacts" v-bind:key="option.id" :value="option.id">
                        {{ option.fullName }}
                    </option>
                </select>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Счет оплаты (ФОП)</label>
                <select :disabled="paymentDetails.length === 0 || !isEnabeled(entity)" class="form-control" v-model="entity.paymentDetailId">
                    <option selected></option>
                    <option v-for="option in paymentDetails" v-bind:key="option.id" :value="option.id">
                        {{ option.name }} {{ option.number}}
                    </option>
                </select>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Оплатил</label>
                <input :disabled="!isEnabeled(entity)" type="text" class="form-control" v-model="entity.paymentPayer">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Время оплаты</label>
                <input :disabled="!isEnabeled(entity)" type="datetime-local" min="0" class="form-control" v-model="entity.paymentTime">
            </div>  
            <div class="mb-3 text-left">
                <label class="form-label">Сумма предоплаты</label>
                <input :disabled="!isEnabeled(entity)" type="number" min="0" class="form-control" v-model="entity.paymentAmount">
            </div>          
            <div class="mb-3 text-left">
                <label class="form-label">Цена</label>
                <input disabled type="number" min="0" class="form-control" v-model="entity.totalCost">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Оплата балансом</label>
                <input disabled type="number" min="0" class="form-control" v-model="entity.bonusPaidAmount">
            </div>                    
        </template>

        <template v-slot:header="{entity}">
            <div class="pt-2 pl-3 pr-3">
                <div class="row">
                    <div class="col-sm mb-3 text-left">
                        <label class="form-label">К оплате (Наложка)</label>
                        <input :disabled="!isEnabeled(entity)" type="number" :min="entity.totalCost- entity.paymentAmount - entity.bonusPaidAmount" class="form-control" v-model="entity.cashPaidAmount">
                    </div>  
                    <div class="col-sm mb-3 text-left">
                        <label class="form-label">Маржа</label>
                        <div class="form-control">{{ (Number(entity.cashPaidAmount) + Number(entity.bonusPaidAmount) + Number(entity.paymentAmount)) - Number(entity.totalCost) }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm mb-3 text-left">
                        <label class="form-label">Тип оплаты</label>
                        <select :disabled="deliveryTypes.length === 0 || !isEnabeled(entity)" class="form-control" v-model="entity.deliveryTypeId" :required="entity.statusId != '1bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId != '2bf61851-347c-4d0d-a62e-d488dce9c0da'">
                            <option v-for="option in deliveryTypes" v-bind:key="option.id" :value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm mb-3 text-left">
                        <label class="form-label">Служба доставки</label>
                        <select :disabled="deliveryServices.length === 0 || !isEnabeled(entity)" class="form-control" v-model="entity.deliveryServiceId" :required="entity.statusId != '1bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId != '2bf61851-347c-4d0d-a62e-d488dce9c0da'">
                            <option v-for="option in deliveryServices" v-bind:key="option.id" :value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>  
                </div>
                <div class="row">
                    <div class="col-sm mb-3 text-left">
                        <label class="form-label">Город</label>
                        <input v-if="entity.deliveryServiceId != '1bf61851-347c-4d0d-a62e-d488dce9c0da'" type="text" class="form-control" :disabled="!isEnabeled(entity)" v-model="entity.cityName" :required="entity.statusId != '1bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId != '2bf61851-347c-4d0d-a62e-d488dce9c0da'">
                        <scity v-else :entity="entity" @update="(value) => { entity.city = value.code; entity.cityName = value.label; }" :required="entity.statusId != '1bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId != '2bf61851-347c-4d0d-a62e-d488dce9c0da'"/>
                    </div>
                    <div class="col-sm mb-3 text-left">
                        <label class="form-label">Адрес</label>
                        <input :disabled="!isEnabeled(entity)" type="text" class="form-control" v-model="entity.address" :required="entity.statusId != '1bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId != '2bf61851-347c-4d0d-a62e-d488dce9c0da'">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm mb-3 text-left">
                        <label class="form-label">Получатель</label>
                        <input :disabled="!isEnabeled(entity)" type="text" class="form-control" v-model="entity.recipient" :required="entity.statusId != '1bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId != '2bf61851-347c-4d0d-a62e-d488dce9c0da'">
                    </div>
                    <div class="col-sm mb-3 text-left">
                        <label class="form-label">Мобильный телефон</label>
                        <input pattern="^\+[0-9]{10,14}" :disabled="!isEnabeled(entity)" type="tel" class="form-control" v-model="entity.recipientPhone" :required="entity.statusId != '1bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId != '2bf61851-347c-4d0d-a62e-d488dce9c0da'">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm mb-3 text-left">
                        <label class="form-label">Коментарий</label>
                        <textarea class="form-control" v-model="entity.comment"></textarea>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:body="{entity, action, onLoadData, save}">
            <order-product-detail 
                v-on:on-parrent-load="onLoadData"
                v-on:main-save="save"
                v-bind:rootId="this.$route.params.id" 
                v-bind:contactId="entity.contactId" 
                v-bind:rootColumn="'ActivityId'"
                v-bind:enabled="action=='update' && isEnabeled(entity)" 
                class="mb-3"/>

            <order-promotion-detail 
                v-bind:rootId="this.$route.params.id"
                v-on:on-parrent-load="onLoadData"
                v-on:main-save="save"
                v-bind:rootColumn="'OrderId'" 
                v-bind:enabled="action=='update' && isEnabeled(entity)" 
                class="mb-3"/>

            <transactions-detail 
                v-if="entity.id" 
                v-bind:rootId="this.$route.params.id"
                v-on:main-save="save" 
                v-bind:contactId="entity.contactId" 
                v-bind:rootColumn="'ActivityId'" 
                v-bind:enabled="action=='update' && isEnabeled(entity)"
                class="mb-3"/>        
        </template>
    </base-page>
</template>

<script>
import scity from "@/components/scity.vue";
import TransactionsDetail from "@/views/details/transactions/TransactionsDetail.vue";
import OrderProductDetail from "@/views/details/products/OrderProductDetail.vue";
import OrderPromotionDetail from "@/views/details/promotions/OrderPromotionDetail.vue";
import BasePage from '@/components/Sections/BasePage.vue';
export default {
    name: "OrderPage",
    components:{ scity, BasePage, TransactionsDetail, OrderProductDetail, OrderPromotionDetail },
    data(){
        return {
            actionInProcess: false,
            statuses:[],
            deliveryTypes:[],
            deliveryServices:[],
            contacts:[],
            paymentStatuses:[],
            paymentDetails:[],
        };
    },
    methods:{
        changeContactId(value, entity) {
            var contactId = this.contacts.find(x => x.fullName == value)?.id;
            if(contactId)
                entity.contactId = contactId;
        },
        isEnabeled(entity){
            return entity?.statusId != "8bf61851-347c-4d0d-a62e-d488dce9c0da" || entity?.statusId != "6bf61851-347c-4d0d-a62e-d488dce9c0da";
        },
        confirmReserv(entity, save){
            entity.statusId = '2bf61851-347c-4d0d-a62e-d488dce9c0da';
            save();
        },
        resort(entity) {
            this.$store.dispatch("post", 
            { 
                table:"Order", 
                method:"resort", 
                body: { Id: entity.id } 
            }).then(response => {
                entity.statusId = response.data.data.statusId;
            })
            .catch(error => this.$toast.error(error.response.data.message));        
        },
        generate(entity) {            
            if(this.actionInProcess === false){
                this.actionInProcess = true;
                this.$store.dispatch("postAPI", { controller: "Order", method: "generateTtn", body: { Id : entity.id } })
                .then(response => {
                    entity.ttn = response.data.data;
                    this.actionInProcess = false;                    
                    this.$toast.success("ТТН успішно згенеровано");
                })
                .catch(error => 
                {
                    this.actionInProcess = false;
                    this.$toast.error(error.response.data.message);
                });
            }
        },
        moveParentOrder(id){
            this.$router.replace({ name: 'OrderPage', params: { id: id }})
                .then(() => this.$router.go()) ;
        },
        cancel(entity) {
            if(this.actionInProcess === false){
                this.actionInProcess = true;
                this.$store.dispatch("post", 
                { 
                    table:"Order", 
                    method:"cancel", 
                    body: { Id: entity.id } 
                }).then(response => {
                    entity.statusId = response.data.data.statusId;
                    this.actionInProcess = false;
                })
                .catch(error => 
                {
                    this.actionInProcess = false;
                    this.$toast.error(error.response.data.message)
                });
            }
            else {
                this.$toast.warning("Запрос в работе ожидайте ответа...")
            }
        },

        confirmSend(entity, save){
            if(entity.ttn === null || entity.ttn === undefined || !(entity.city || entity.cityName) || !entity.address || !entity.deliveryTypeId
                || !entity.deliveryServiceId || !entity.recipient || !entity.recipientPhone) {
                this.$toast.warning("Заполните все объязательные поля для коррекного проведения заказа в системе.");
                this.$toast.warning("Заполните информацию о получателе, адресе доставки и т.п");
                this.$toast.warning("Для перевода в состояние отправлено заполните поле ТТН");
                return;
            }
            entity.statusId = '5bf61851-347c-4d0d-a62e-d488dce9c0da';
            save();
        },
        confirmOrder(entity, save) {
            if ((!entity.city && !entity.cityName) || !entity.address || !entity.deliveryTypeId
                || !entity.deliveryServiceId || !entity.recipient || !entity.recipientPhone){
                    this.$toast.info("Заполните все объязательные поля для коррекного проведения заказа в системе");
                    this.orderConfirm(entity, save);
                    return;
                }
            entity.paymentStatusId = '1bf61851-347c-4d0d-a62e-d488dce9c0da';
            entity.statusId = '3bf61851-347c-4d0d-a62e-d488dce9c0da';
            save();
        },

        orderConfirm(entity, save) {            
            entity.paymentStatusId = '1bf61851-347c-4d0d-a62e-d488dce9c0da';
            entity.statusId = '0bf62851-347c-4d0d-a62e-d488dce9c0da';
            save();
        },
        confirmDone(entity, save){
            entity.statusId = '6bf61851-347c-4d0d-a62e-d488dce9c0da';
            save();
        },
        confirmRefund(entity, save){
            entity.statusId = '7bf61851-347c-4d0d-a62e-d488dce9c0da';
            save();
        },

        setCitySelected(input, entity){
            entity.cityName = input;
        },

        loadLookups() {
            this.$store.dispatch('section', { table: "orderStatus" })
            .then((response) => {
                this.statuses = response.data.data;
            });
            this.$store.dispatch('section', { table: "deliveryType" })
            .then((response) => {
                this.deliveryTypes = response.data.data;
            });
            this.$store.dispatch('section', { table: "deliveryService" })
            .then((response) => {
                this.deliveryServices =  response.data.data;
            });            
            this.$store.dispatch('section', { table: "contact" })
            .then((response) => {
                this.contacts =  response.data.data;
            });
            this.$store.dispatch('section', { table: "paymentStatus" })
            .then((response) => {
                this.paymentStatuses =  response.data.data;
            });
            this.$store.dispatch('section', { table: "paymentdetail" })
            .then((response) => {
                this.paymentDetails = response.data.data;
            });    
        }
    },
    mounted(){
        this.loadLookups();
    }
}
</script>