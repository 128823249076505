<template>
    <div class="scroll-enabeled">
        <div class="swagger" id="swagger"></div>
    </div>
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';

export default {
    name: "SwaggerPage",
    mounted() {
        SwaggerUI({
            url: "swagger/v1/swagger.json",
            dom_id: '#swagger'
        })
    }
}
</script>

<style scoped>
.scroll-enabeled {
    max-height: 90vh;
    overflow-y: scroll;
    text-align: left;
}
</style>