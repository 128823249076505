<template>
    <t-section v-bind:schemaName="'subscribe'" v-bind:fields="fields" v-bind:isHideButton="true" v-bind:headers="headers"></t-section>
</template>

<script>
    import TSection from '@/components/Sections/Section.vue';
    export default {
        name: "SubscribeSection",
        components:{
            TSection,
        },
        data() {
            return {
                headers:[
                    { field:"contact.fullName", name:"Контакт", sort:true},
                    { field:"remains.product.code", name:"Продукт", sort:true},
                    { field:"remains.character.code", name:"Размер", sort:true},
                    { field:"lifeline", name:"Подписка к", sort:true, isDate:true},
                ],
                fields:[],
            }
        }
    }
</script>