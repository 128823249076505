<template>
  <base-detail
    v-bind:title="'Переможці'"
    v-bind:rootId="rootId"
    v-bind:rootColumn="rootColumn"
    v-bind:schemaName="'CampaignWinner'"
    v-bind:headers="headers"
    v-bind:entity="entity"
    v-on:main-save="main - save"
    v-on:on-parrent-load="on - parrent - load"
  >
  </base-detail>
</template>

<script>
import BaseDetail from '@/components/Sections/BaseDetail.vue'
export default {
  name: 'CampaignWinnerDetail',
  components: { BaseDetail },
  props: {
    rootId: { type: String, require: true },
    rootColumn: { type: String, require: true },
    enabled: { type: Boolean },
  },
  data() {
    return {
      entity: {
        campaignId: this.rootId,
        award: 0,
        position: 0,
      },
      headers: [
        {
          field: 'place',
          name: 'Місце',
        },
        {
          field: 'contact.fullName',
          name: 'Нагорода',
        }
      ],
    }
  },
}
</script>
