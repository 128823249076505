<template>
  <base-page v-bind:schemaName="'campaign'">
    <template v-slot:panel-left="{ entity }">
      <div class="mb-3 text-left">
        <label class="form-label">Назва</label>
        <input type="text" class="form-control" v-model="entity.name" required />
      </div>
      <div class="mb-3 text-left">
        <label class="form-label">Статус</label>
        <select
          :disabled="statutes.length === 0"
          class="form-control"
          v-model="entity.statusId"
        >
          <option v-for="option in statutes" v-bind:key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
      <div class="mb-3 text-left">
        <label class="form-label">Час початку розрахунку</label>
        <input type="date" min="0" class="form-control" v-model="entity.startOn" />
      </div>
      <div class="mb-3 text-left">
        <label class="form-label">Час закінчення розрахунку</label>
        <input type="date" min="0" class="form-control" v-model="entity.finishOn" />
      </div>
    </template>
    <template v-slot:body="{ action, onLoadData, save }">
      <campaign-requirement-detail
        v-on:on-parrent-load="onLoadData"
        v-on:main-save="save"
        v-bind:rootId="this.$route.params.id"
        v-bind:rootColumn="'CampaignId'"
        v-bind:enabled="action == 'update'"
      />
      <campaign-winner-detail
        v-bind:rootId="this.$route.params.id"
        v-bind:rootColumn="'CampaignId'"
        v-bind:enabled="action == 'update'"
      />
    </template>
  </base-page>
</template>

<script>
import BasePage from '@/components/Sections/BasePage.vue'
import CampaignRequirementDetail from '@/views/campaign/details/CampaignRequirementDetail.vue'
import CampaignWinnerDetail from '@/views/campaign/details/CampaignWinnerDetail.vue'
export default {
  name: 'CampaignPage',
  components: { BasePage, CampaignRequirementDetail, CampaignWinnerDetail },
  data() {
    return {
      statutes: [],
    }
  },
  methods: {
    async loadLookups() {
      let response = await this.$store.dispatch('section', { table: 'campaignstatus' })
      this.statutes = response.data.data
    },
  },
  async mounted() {
    await this.loadLookups()
  },
}
</script>
