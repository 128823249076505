<template>
    <base-page v-bind:schemaName="'message'" v-bind:isHideSave="true" v-bind:isHideRemove="true">
    <template v-slot:panel-left="{entity}">
            <div class="mb-3 text-left">
                <label class="form-label">Дата создания</label>
                <input :disabled="true" type="text" class="form-control" v-model="entity.createdOn">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Контакт</label>
                <label class="form-control">{{ entity.contact?.fullName }}</label>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Идентификатор</label>
                <input :disabled="true" type="text" class="form-control" v-model="entity.identifier">
            </div>
    </template>
    <template v-slot:body="{entity}">
            <div class="mb-3 text-left">
                <label class="form-label">Сообщение</label>
                <textarea :disabled="true" type="text" class="form-control textarea-size" v-model="entity.text"></textarea>
            </div>
    </template>
    </base-page>
</template>

<script>
import BasePage from '@/components/Sections/BasePage.vue';
export default {
    name: "MessagePage",
    components:{ BasePage }
}
</script>
<style scoped>
.textarea-size{
    height: 80vh;
}
</style>