<template>
    <form v-on:submit.prevent="save">
        <div class="text-left ml-2 pl-3 pt-2 pr-3">
            <div class="bg-white rounded shadow">
                <div class="btn-group">
                    <button type="submit" :disabled="loading" v-if="!isHideSave" class="btn btn-sm btn-outline-success">{{ $filters.localize("button.save") }}</button>
                    <button type="button" v-on:click="$router.go(-1)" class="btn btn-sm btn-outline-primary">{{ $filters.localize("button.close") }}</button>
                    <button type="button" :disabled="loading" v-if="action ==='update' && !isHideRemove" v-on:click="remove" class="btn btn-sm btn-outline-danger">{{ $filters.localize("button.remove") }}</button>
                    <slot name="panel-button" v-bind:entity="entity" v-bind:save="save"></slot>
                </div>
            </div>
        </div>
        <div class="row container-scroll m-0">
            <div class="col-sm-3 bg-white rounded shadow w-100 m-2">
                <div class="mt-2">
                    <slot name="panel-left" :entity="entity" :action="action"></slot>
                </div>
            </div>
            <div class="col-sm pl-0">
                <div class="bg-white rounded shadow mh-20 w-100 mt-2 mb-2">
                    <div class="mt-2">
                        <slot name="header" :entity="entity" :action="action"></slot>
                    </div>
                </div>
                <div class="bg-white p-2 rounded shadow w-100 mb-2">
                    <slot name="body" :entity="entity" :onLoadData="onLoadData" :action="action" :save="save"></slot>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: "BasePage",
    props: ["schemaName", "isHideRemove", "isHideSave"],
    data: () => ({
        action: "add",
        entity: { id: null },
        loading: false
    }),
    mounted() {
        this.entity.id = this.$route.params.id ? this.$route.params.id : this.$router.query?.id ? this.$router.query.id : this.entity.id;
        this.onLoadData();
    },
    computed:{
        request:{
            get(){
                var keys = Object.keys(this.entity).filter(x => x.includes("Id")).map(x => x.replace("Id", ""));
                var obj = this.entity;
                keys.forEach(x => obj[x] = null)
                return JSON.parse(JSON.stringify(obj))
            }
        },
        basePage: {
            get(){
                return this.baseSection + 'Page';
            }
        },
        baseSection:{
            get(){
                return this.schemaName.charAt(0).toUpperCase() + this.schemaName.slice(1);
            }
        }
    },
    methods: {
        save(event, resolve, reject) {
            this.loading = true;
            return this.$store.dispatch(this.action, { table:this.schemaName, body: this.request })
                .then((response) => {
                    this.entity = response.data.data;
                    this.$toast.success("Успешно сохранено");
                    resolve?.call(response);
                    this.loading = false;
                    if (this.action == "add")
                    {
                        window.location.reload();
                    }
                    this.action = "update";
                })
                .catch(error => {
                    this.$toast.error("Системная ошибка. Не удалось обновить данные");
                    reject?.call(error);
                    this.loading = false;
            });
        },

        remove() {
            this.$store.dispatch("remove", { table:this.schemaName, body: this.request })
                .then(()=> {
                    this.$toast.success("Данные успешно удалены");
                    this.$router.go(-1);
                }).error((error) => {
                    this.$toast.error(error.response.data.Message);
            });
        },

        onLoadData(){
            this.$store.dispatch('index', { table:this.schemaName, body: this.request })
            .then((response) => {
                var result = response.data;
                this.entity = result.data;
                this.$route.params.id = this.entity.id;
                this.action = result.status ? "update" : "add";
            }).catch(() => {
                this.$toast.error("Системная ошибка. Не удалось получить информацию");
            });
        },
    }
}
</script>

<style scoped>
.container-scroll {
    overflow: scroll;
    max-height: calc(100vh - 100px);
}
</style>