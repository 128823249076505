<template>
  <t-section
    v-bind:schemaName="'campaign'"
    v-bind:fields="fields"
    v-bind:headers="headers"
    v-bind:isHideChart="true"
  >
  </t-section>
</template>

<script>
import TSection from '@/components/Sections/Section.vue'
export default {
  name: 'CampaignSection',
  components: {
    TSection,
  },
  computed: {
    fields: {
      get() {
        return [
          { field: 'name', name: 'Назва', type: 'string' },
          { field: 'finishOn', name: 'Дата завершення', type: 'date' },
          {
            field: 'statusId',
            name: 'Статус',
            type: 'lookup',
            lookup: this.lookups.status,
          },
        ]
      },
    },
  },
  data() {
    return {
      lookups: {
        status: [],
      },
      headers: [
        {
          field: 'name',
          name: 'Назва',
          sort: true,
          router: { field: 'id', name: 'CampaignPage' },
        },
        {
          field: 'finishOn',
          name: 'Дата завершення',
          isDate: true,
          router: { field: 'id', name: 'CampaignPage' },
        },
        {
          field: 'status.name',
          name: 'Статус',
          sort: true,
          filter: true,
          router: { field: 'id', name: 'CampaignPage' },
        },
      ],
    }
  },
  methods: {
    async loadLookups() {
      let response = await this.$store.dispatch('section', {
        table: 'campaignstatus',
        body: {},
      })
      this.lookups.status = response.data.data
    },
  },
  async mounted() {
    await this.loadLookups()
  },
}
</script>
