export default {
    data(){
        return {
            cities:[],
            cityName: "",
            city: null,            
            warehouses:[],
            warehouse: null,
            warehouseId:""
        }
    },
    computed:{
        cityData:{
            get() {
                return this.cityName;
            },
            set(value) {
                this.cityName = value;
                this.getCities(value);
                this.setCityId(value);
            }
        },
        warehouseData:{
            get(){
                return this.warehouseId;
            },
            set(value){
                this.warehouseId = value;
                this.setWarehouse(value);
            }
        }
    },
    methods: {
        getCities(name) {
            if(name){
                this.$store.dispatch('getAPI', { controller: "address", method: "getCity/" + name })
                .then((response) => {
                    this.cities = response.data.data;
                });
            }
        },

        getWarehouse(city) {
            this.warehouses = [];
            if(city){
                this.$store.dispatch('getAPI', { controller: "address", method: "getWarehouse/" + city })
                .then((response) => {
                    this.warehouses = response.data.data;
                });
            }
        },

        setCityId(city){
            this.city = this.cities.filter(x => x.description == city)[0];
            this.getWarehouse(this.city?.Ref);
        },

        setWarehouse(id){
            this.warehouse = this.warehouses.filter(x => x.Ref == id)[0];
        }
    },
}