export default {
    methods: {
        getRowValue(row, config) {
            var data = config?.field?.split('.');
            data?.forEach(field => {
                try {
                    row = row[field];
                }
                catch {
                    return "";
                }
            });
            if (config?.isDate) {
                return new Date(row).toLocaleString();
            }
            if(config?.isBoolean){
                return row == true ? "Так" : "Ні"; 
            }
            return row;
        },
        routerOpen(id, name){
            if(name){
                var routeData =  this.$router.resolve({ name: name, params: { id: id }});
                window.open(routeData.href, '_blank');
            }  
        },
        router(id, name) {
            if(name){
                this.$router.push({ name: name, params: { id: id }});
            }
        },
    },
}