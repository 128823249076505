<template>
    <t-section v-bind:schemaName="'paymentDetail'" v-bind:fields="fields" v-bind:headers="headers"></t-section>
</template>

<script>
    import TSection from '@/components/Sections/Section.vue';
    export default {
        name: "PaymentDetailSection",
        components:{
            TSection,
        },
        data() {
            return {
                lookups:{
                    status:[]
                },
                headers:[
                    { field:"createdOn", name:"Дата создания", isDate: true, router: { field:"id", name:"PaymentDetailPage" }},
                    { field:"name", name:"Название", sort:true, router: { field:"id", name:"PaymentDetailPage" }},
                    { field:"number", name:"Номер", sort:true, router: { field:"id", name:"PaymentDetailPage" }},
                ]
            }
        },
        computed:{
            fields:{
                get(){
                    return [                        
                        { field: "name", name: "Название", type:"string" },
                        { field: "number", name: "Номер", type:"string" },
                    ];
                }
            }
        }
    }
</script>